import { Component } from 'react'
import finvu from '../../assets/finvu.png';


export default class Header extends Component {
    constructor(props) {
      super(props)
    
      this.state = {}
    }
    render() {
        return (
            <nav className="navbar header-color fixed-top py-3" style={{backgroundColor: this.props.customDetails?.secondaryColor}}>
                <div className="container">
                    <a className="navbar-brand d-flex justify-content-between w-100 align-items-center" href="#">
                       
                        <div className='d-flex'>
                            {/* {this.props.customerID !=='default' ? 
                             <img src={this.props.customDetails?.logo} className='bank-logo' />
                            :
                            <img src={navLogo} className='bank-logo' />
                            } */}
                            <img src={this.props.customDetails?.logo} className='bank-logo' />
                           
                            {/* <h1 className='ms-4 fs-24 fw-bold mb-0' style={{ color: '#1168B4', alignSelf: 'center' }}>HDFC</h1> */}
                        </div>
                        <div className="d-flex">
                            <img src={finvu} className='finvu-logo float-end' height={'50px'}/>
                        </div>
                    </a>
                </div>
            </nav>
        )
    }
}