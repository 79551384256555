import { Component } from 'react'

export default class ConsentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consentDetails: null
    };
  }
  componentDidMount() {
    if(this.props?.location?.state?.data){
      this.setState({ consentDetails: this.props?.location?.state?.data })
    }else{
      this.props.history.push('/')
    }
  }
  capitalize = (s) => {
    return s && s[0].toUpperCase() + s.slice(1).toLowerCase();
  }
  dateFormat(dateString, showTime) {
    if (dateString) {
      let newDate = new Date(dateString);
      let dataArray = newDate.toString().split(' ');
      console.log('dataArray::', dataArray)
      if (showTime) {
        return `${dataArray[2]} ${dataArray[1]} ${dataArray[3]} ${dataArray[4]}`;
      }
      return `${dataArray[2]} ${dataArray[1]} ${dataArray[3]}`;
    }
  }
  render() {
    return (
      <section className='consent-details-page mt-10'>
        <div className='row gx-0'>
          <div className='col-lg-4 offset-lg-4 col-md-8 offset-md-2'>
            <div className='row pt-3 px-3'>
              <div className='col-12'>
                <p className='fs-14 fw-700 text-black text-start'>DETAILS OF ACCESS</p>
              </div>
              <div className='col-12'>
                <p className='fs-14 fw-600 text-black text-start'>ACCOUNTS</p>
              </div>
              {
                this.state.consentDetails?.Accounts?.map((el, i) => {
                  return (
                    <div className="row" key={i}>
                      <div className='col-12'>
                        <h4 className='fs-14 fw-700 text-info text-start'>{el?.entityName}</h4>
                      </div>
                      <div className='col-6'>
                        <p className='fs-12 fw-500 text-muted text-start'>{el?.accType}</p>
                      </div>
                      <div className='col-6'>
                        <p className='fs-12 fw-500 text-muted text-end'>{el?.maskedAccNumber}</p>
                      </div>
                    </div>
                  )
                })
              }


              {/* <div className='col-12'>
                <h4 className='fs-14 fw-700 text-info text-start'>AXIS BANK</h4>
              </div>
              <div className='col-6'>
                <p className='fs-12 fw-500 text-muted text-start'>SAVINGS</p>
              </div>
              <div className='col-6'>
                <p className='fs-12 fw-500 text-muted text-end'>XXXXXXX7564</p>
              </div> */}

              <div className='col-12 mt-3'>
                <h4 className='fs-14 fw-700 text-info text-start'>{this.capitalize(this.state?.consentDetails?.fetchType)}</h4>
              </div>
              <div className='col-12 mb-3'>
                <p className='fs-12 fw-600 text-muted text-start'>{this.state?.consentDetails?.fetchType === 'ONETIME' ? 'Information fetch one time' : `Information fetch ${this.state?.consentDetails?.Frequency?.value} times a ${this.state?.consentDetails?.Frequency?.unit}`}</p>
              </div>
              <div className='col-6'>
                <h4 className='fs-14 fw-600 text-black text-start'>From Date</h4>
                <p className='fs-12 fw-600 text-muted text-start'>{this.dateFormat(this.state.consentDetails?.DataDateTimeRange?.from, false)}</p>
              </div>
              <div className='col-6'>
                <h4 className='fs-14 fw-600 text-black text-end'>To Date</h4>
                <p className='fs-12 fw-600 text-muted text-end'>{this.dateFormat(this.state.consentDetails?.DataDateTimeRange?.to, false)}</p>
              </div>
              <div className='col-12 mt-3'>
                <h4 className='fs-14 fw-700 text-info text-start'>Account Information</h4>
              </div>
              <div className='col-12 mb-3'>
                <p className='fs-12 fw-600 text-muted text-start'>{this.state.consentDetails?.consentTypes.map((el, i) => { return (<span className='mr-2' key={i}>{this.capitalize(el).replace(/[-_]/g, ' ')}{`${(this.state.consentDetails?.consentTypes?.length - 1) === i ? '' : ','}`}</span>) })}</p>
              </div>
              <div className='col-6'>
                <h4 className='fs-14 fw-600 text-black text-start'>Data {this.capitalize(this.state.consentDetails?.mode)}</h4>
                <p className='fs-12 fw-600 text-muted text-start'>Until {this.state.consentDetails?.DataLife?.value} {this.state.consentDetails?.DataLife?.unit}</p>
              </div>
              <div className='col-6'>
                <h4 className='fs-14 fw-600 text-black text-end'>Consent Expiry</h4>
                <p className='fs-12 fw-600 text-muted text-end'>{this.dateFormat(this.state.consentDetails?.expireTime, false)}</p>
              </div>
              <div className='col-12 mt-3'>
                <h4 className='fs-14 fw-700 text-info text-start'>Account Types</h4>
              </div>
              <div className='col-12 mb-3'>
                <p className='fs-12 fw-600 text-muted text-start'>{this.state.consentDetails?.fiTypes.map((el, i) => { return (<span className='mr-2' key={i}>{this.capitalize(el).replace(/[-_]/g, ' ')}{`${(this.state.consentDetails?.fiTypes?.length - 1) === i ? '' : ','}`}</span>) })}</p>
              </div>
              <div className='col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-12 d-flex justify-content-center'>
              <button type="button"  className="btn float-end px-5 fs-16 fw-600 text-white" style={{ height: this.props.customDetails?.btnHeight, width: this.props.customDetails?.btnWidth, borderRadius: this.props.customDetails?.borderRadius, backgroundColor: this.props.customDetails?.primaryColor, fontFamily: this.props.customDetails?.fontFamily }} 
                 onClick={() => {
                  this.props.history.push({
                    pathname: `/revoke-consent`,
                    state: { selectedAccounts:this.props?.location?.state?.selectedAccounts,number:this.props?.location?.state?.number }
                  })
                }}>
                 BACK
                </button>
                                </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
