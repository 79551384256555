export const postMessage = (action, status) => {
    var obj = { action: action, status: status, source: "finvu" };
    var myParent: Window = window.top;
    if (window.parent !== window.top) {
        myParent = window.parent;
    }
    myParent.postMessage(obj, '*');
    if (window["ReactNativeWebView"]) {
        // Post event to react native if this is running in react native.
        (window["ReactNativeWebView"]).postMessage(JSON.stringify(obj));
    } else if (window["FinvuAndroid"]) {
        // Post event if this is running in Android webview.
        (window["FinvuAndroid"]).postMessage(JSON.stringify(obj));
    } else if (window["webkit"]) {
        // Post event if this is running in IOS webview.
        if ((window["webkit"]).messageHandlers["FinvuIOS"]) {
            ((window["webkit"]).messageHandlers["FinvuIOS"]).postMessage(JSON.stringify(obj));
        }
    }
}