import { toast } from 'material-react-toastify';
import { Component } from 'react';
import status from '../../src/assets/status.png';
import AuthApis from '../helper/api.service';
import { postMessage } from '../utils/common.utils';

export default class Status extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogout: false,
    };
    this.number = props?.location?.state?.number;
    this.authApis = new AuthApis()
  }
  componentDidMount() {

  }
  logout = () => {
    this.authApis.logout(`${this.number}@finvu`).then(res => {
      console.log("res", res);
      if (res.status === 'SUCCESS') {
        this.setState({ isLogout: true })
        postMessage('window', 'CLOSED')
        console.log(res.message)
      } else {
        if (res.message === "Login Required") {
          this.props.history.push('/')
        }
        toast.error(res.message)
        console.log(res.message)
      }
    })
  }

  render() {
    return (
      <section className='status-page mt-10'>
        <div className=''>
          <div className='col-lg-4 offset-lg-4 col-md-8 offset-md-2 col-12'>
            <div className='pt-5 mx-5'>

              <p className='fs-14 fw-600 text-danger '>Please DO NOT hit Refresh or Back</p>
              <img src={status} className='mt-4' />
              <p className='fs-18 fw-700 text-info mt-4'>Consents Revoked</p>
              <p className='fs-14 fw-500 text-black-50 mb-4'>Institutions have been notified</p>
              <p className='fs-14 fw-500 text-black-50 mb-4' style={{ textAlign: 'left' }}>
                Your data will not be shared with the institution, If you continue to see data on your institution application, kindly contact them to clear the data
              </p>
              <div className='row'>

                <div className='col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-12 d-flex justify-content-center'>
                  <button disabled={this.state.isLogout} type="button" className="btn float-end px-5 fs-16 fw-600 text-white" style={{ height: this.props.customDetails?.btnHeight, width: this.props.customDetails?.btnWidth, borderRadius: this.props.customDetails?.borderRadius, backgroundColor: this.props.customDetails?.primaryColor, fontFamily: this.props.customDetails?.fontFamily }}
                    onClick={this.logout}  >
                    OKAY
                  </button>
                </div>
                <div className='col-12 mt-3'>
                  <button disabled={this.state.isLogout} type="button" className="btn btn-link " style={{ fontFamily: this.props.customDetails?.fontFamily, textDecoration: 'none' }}
                    onClick={() => {
                      this.props.history.push({
                        pathname: `/revoke-consent`,
                        state: { number: this.props?.location?.state?.number }
                      })
                    }}  >
                    REVOKE OTHER CONSENTS
                  </button>
                </div>
                {
                  this.state.isLogout ?
                    <p className='fs-14 fw-600 text-danger mt-5'> You are logged out. You may close this window</p>
                    : null
                }

              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
